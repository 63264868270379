export const animate = {
  intersection: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
};
