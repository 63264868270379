import React, {useEffect, useContext, useRef} from 'react';
import {Context} from '../context.js';
import useOnScreen from '../util/useOnScreen';
import {motion} from 'framer-motion';
import {animate} from '../util/utils';
import TextSelector from './TextSelector';

export default function Randrum() {
    const {value, setValue} = useContext(Context);
    const video = useRef(null);
    const [ref, visible] = useOnScreen();
    useEffect(() => {
        if (value.activeMediaSrc !== 'randrum') {
            video.current.pause();
        }
    }, [value.activeMediaSrc]);

    return (
        <div ref={ref}>
            <motion.video
                className="shadow-box"
                ref={video}
                src="./resources/randrum-crop.mp4#t=0"
                preload="metadata"
                controls
                playsInline
                initial={animate.intersection.from}
                animate={
                    visible
                        ? animate.intersection.to
                        : animate.intersection.from
                }
                poster="/resources/randrum-poster.png"
                onClick={() => {
                    setValue({...value, activeMediaSrc: 'randrum'});
                }}
            />
            <TextSelector>
                <i lang="de" className="caption">
                    Randrum spielt zufällige Noten der Moll-Tonleiter. Gepatcht
                    mit{' '}
                    <a
                        href="https://cycling74.com/products/max"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Max/Msp
                    </a>
                </i>
                <i lang="en" className="caption">
                    Randrum plays random notes on a minor scale. Its written
                    using{' '}
                    <a
                        href="https://cycling74.com/products/max"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Max/Msp
                    </a>
                </i>
            </TextSelector>
        </div>
    );
}
