import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import useOnScreen from '../util/useOnScreen';
import { animate } from '../util/utils';
import TextSelector from './TextSelector';

export default function Heartbreaker() {
    const [ref, visible] = useOnScreen();
    const iframe = useRef();

    function reloadFrame() {
        iframe.current.src = './resources/fornever_mobile/index.html';
    }
    return (
        <div ref={ref}>
            <motion.iframe
                ref={iframe}
                initial={animate.intersection.from}
                animate={
                    visible
                        ? animate.intersection.to
                        : animate.intersection.from
                }
                title="heartbreaker"
                height="400"
                width="360"
                src="./resources/fornever_mobile/index.html"
            ></motion.iframe>
            <TextSelector>
                <i lang="de" className="caption">
                    Meine erste WebApp aus 2014 im iFrame. Trivia: Der Name Heartbreaker beruht auf meiner ersten Erfahrung mit Cross-Browser-Kompatibilität.
                    <button className="frame-btn" onClick={reloadFrame}>reload</button>
                </i>
                <i lang="en" className="caption">
                    My first WepApp from 2014 inside an iFrame. Trivia: The Heartbreaker got its name from the first lessons Cross-Browser-Compatibility taught me.
                    {' '}
                    <button className="frame-btn" onClick={reloadFrame}>reload</button>
                </i>
            </TextSelector>
        </div>
    );
}
