import React, {useContext} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {Context} from '../context.js';

export default function TextSelector(props) {
    const {value} = useContext(Context);
    return (
        <AnimatePresence exitBeforeEnter>
            {props.children.map((element, index) => {
                if (element.props.lang === value.lang) {
                    return (
                        <motion.div
                            key={index}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.1}}
                        >
                            {element}
                        </motion.div>
                    );
                }
            })}
        </AnimatePresence>
    );
}
