import React, { useState } from 'react';
import Greeting from './Components/Greeting';
import Randrum from './Components/Randrum';
import Heartbreaker from './Components/Heartbreaker';
import BTBox from './Components/BTBox';
import Socialclub from './Components/Socialclub';
import Music from './Components/Music';
import { Context, initialState } from './context.js';
import TextSelector from './Components/TextSelector.js';
import useLightSwitch from './util/useLightSwitch';

function App() {
    const [value, setValue] = useState(initialState);
    useLightSwitch(value.lang);
    return (
        <Context.Provider value={{ value, setValue }}>
            <div className="App">
                <section>
                    <Greeting />
                    <TextSelector>
                        <p lang="de">
                            ich bin mir nicht ob ich es in meinen Unterlagen
                            erwähnt habe, aber wir können uns auch auf{' '}
                            <button
                                className="lang-btn"
                                onClick={() => setValue({ ...value, lang: 'en' })}
                            >
                                {`Englisch`}
                            </button>
                            unterhalten. Abgesehen davon gibt es auch sonst noch das ein
                            oder andere zu meiner Person, was sich nur schwer in einer Bewerbung unterbringen lässt,
                            aber trotzdem interessant für Sie sein könnte.
                        </p>
                        <p lang="en">
                            i'm not sure if i mentioned it before but we can have a conversation in{' '}
                            <button
                                className="lang-btn"
                                onClick={() => setValue({ ...value, lang: 'de' })}
                            >
                                german
                            </button>{' '}
                            too, if you like. There may be one or two things about me, which
                            hardly fit into an application but might be
                            worth for you to take into consideration.
                        </p>
                    </TextSelector>
                </section>
                {/** Music Section */}
                <section>
                    <TextSelector>
                        <p lang="de">
                            Ich habe vor vielen Jahren mal Schlagzeug in einer
                            Punkrockband gespielt, doch die meiste Musik habe
                            ich mit Computern gemacht.
                        </p>
                        <p lang="en">
                            Many years ago i used to play the drums in a
                            punkrock band but i wrote most of my music using
                            computers.
                        </p>
                    </TextSelector>
                    <Music />
                    <TextSelector>
                        <p lang="de">
                            Bis Heute eines meiner liebsten Hobbys, gab es mir
                            über die Jahre immer wieder die Gelegenheit auch mit
                            anderen digitalen Medien in Berührung zukommen.
                        </p>
                        <p lang="en">
                            It is to this day one of my favourite hobbys and has always given me a good reason to get in touch with other forms of digital media too.
                        </p>
                    </TextSelector>
                </section>
                {/** Randrum Section */}
                <section>
                    <TextSelector>
                        <p lang="de">
                            Vom Erstellen eigener 'Plattencover', dem Editieren
                            von Videos bis hin zum Entwickeln eigener Audio- und
                            Midieffekte.{' '}
                        </p>
                        <p lang="en">
                            Creating Coverart, editing Video or even the
                            development of my own Audio- and Midi-Effects.
                        </p>
                    </TextSelector>
                    <Randrum />
                </section>
                {/** Javascript Section */}
                <section>
                    <TextSelector>
                        <p lang="de">
                            Meine ersten Zeilen Javascript habe ich in Zeiten
                            von jQuery geschrieben. Viele der Grundsätze und
                            Best Practices von damals sind mit denen der heute gängigen Frameworks kaum
                            noch zu vergleichen.
                        </p>
                        <p lang="en">
                            I wrote my first lines of javascript in times of
                            jQuery. What used to be essential and considered
                            Best Practices back then is hardly comparable to the frameworks used today.
                        </p>
                    </TextSelector>
                    <Heartbreaker />
                </section>
                {/** Socialclub Section */}
                <section>
                    <Socialclub />
                    <TextSelector>
                        <p lang="de">
                            Mit Firebase als <i>serverless Backend</i>{' '}
                            beinhaltet der Socialclub alles, was zu einer
                            modernen Webseite gehört.
                            <br />
                            Das Frontend ist momentan im Umbau,
                            den Code der REST API finden Sie aber zusammen mit dem dieser Seite
                            auf{' '}
                            <a
                                href="https://github.com/formtief?tab=repositories"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Github
                            </a>{' '}
                        </p>
                        <p lang="en">
                            With Firebase as <i>serverless Backend</i> the socialclub covers all
                            the features of a modern web app. I'm rewriting the frontend at the moment but you can find the REST API, as well as this sites code on{' '}
                            <a
                                href="https://github.com/formtief?tab=repositories"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Github
                            </a>
                            .{' '}
                        </p>
                    </TextSelector>
                </section>
                {/** Bluetooth Section */}
                <section>
                    <TextSelector>
                        <p lang="de">
                            Ich habe nicht nur Code für den Browser geschrieben.
                            Für diese Bluetooth Box habe ich sowohl die
                            Firmware für den verbauten ESP32 Chip programmiert,
                            als auch die iOS App entwickelt.
                        </p>
                        <p lang="en">
                            I also wrote code that runs outside a Browserwindow.
                            I developed the iOS App as well as the firmware
                            running on the built-in ESP32 Chip for this
                            Bluetooth Box.
                        </p>
                    </TextSelector>
                    <BTBox />
                    <TextSelector>
                        <p lang="de">
                            Ich möchte an dieser Stelle auf die sonst übliche
                            Aufzählung von Software, Frameworks und
                            Programmiersprachen, die ich über die Jahre
                            sonst noch kennengelernt habe, verzichten.
                            <br />
                            Was aber nicht heißen soll, dass ich nicht gerne mit
                            Ihnen über ihre Toolchain sprechen würde.
                        </p>
                        <p lang="en">
                            I would like to skip the usual list with
                            software, frameworks and programming languages
                            that i also got to know over the years, at this point. <br />But that
                            does not mean that im not interessted in a
                            conversation about your toolchain.
                        </p>
                    </TextSelector>
                </section>
                {/** Ending Section */}
                <section>
                    <TextSelector>
                        <p lang="de">
                            Letztlich zeigt auch diese Seite nur einen Teil von
                            dem, was ich kenne und kann. Ich hoffe trotzdem, dass
                            sie Ihnen einen ersten Eindruck darüber vermitteln konnte,
                            was Sie von mir erwarten können.
                        </p>
                        <p lang="en">
                            In the end, this page still covers only a part of
                            everything i know when it comes to software development. But i hope i was able
                            to give you an first impression of what to expect from me.
                        </p>
                    </TextSelector>
                    <br />
                    <br />
                    <i>David Weigand</i>
                </section>
            </div>
        </Context.Provider>
    );
}

export default App;
