import React, {useState} from 'react';
import {motion} from 'framer-motion';
import useOnScreen from '../util/useOnScreen';
import {animate} from '../util/utils';
import useNewsletter from '../util/useNewsletter';

export default function Socialclub() {
    const [ref, visible] = useOnScreen();
    const [form, setForm] = useState({submitting: false, email: '', error: ''});
    const [response, send] = useNewsletter();

    const submit = e => {
        e.preventDefault();
        setForm({...form, submitting: true});
        send(form, setForm);
    };
    return (
        <div ref={ref}>
            <motion.div
                className="scl-container"
                initial={animate.intersection.from}
                animate={
                    visible
                        ? animate.intersection.to
                        : animate.intersection.from
                }
            >
                <svg className="banner" viewBox="0 0 320 170">
                    <symbol id="banner-text">
                        <text textAnchor="start" y="80" x="40">
                            rdm_
                        </text>
                    </symbol>
                    <g className="banner-copies">
                        <use
                            xlinkHref="#banner-text"
                            className="text-copy"
                        ></use>
                        <use
                            xlinkHref="#banner-text"
                            className="text-copy"
                        ></use>
                        <use
                            xlinkHref="#banner-text"
                            className="text-copy"
                        ></use>
                        <use
                            xlinkHref="#banner-text"
                            className="text-copy"
                        ></use>
                    </g>
                    <text x="0" y="160" className="banner-secondary">
                        socialclub
                    </text>
                </svg>
                <div className="scl-form">
                    {!form.submitting ? (
                        <form onSubmit={submit}>
                            <input
                                className="scl-form-input"
                                id="scl-form-email"
                                type="email"
                                value={form.email}
                                placeholder="enter email to receive newsletter"
                                onChange={e => setForm({email: e.target.value})}
                            />
                            {form.error && (
                                <p className="scl-form-error">{form.error}</p>
                            )}
                        </form>
                    ) : response && response.message ? (
                        <p className="scl-form-success">{response.message}</p>
                    ) : (
                        <span className="scl-form-progress">sending</span>
                    )}
                </div>
            </motion.div>
        </div>
    );
}
