import { useState } from 'react';

export default function useNewsletter() {
    const [response, setResponse] = useState(null);
    const send = async function (form, setForm) {
        try {
            const res = await fetch(
                'https://europe-west1-frnvrde.cloudfunctions.net/api/newsletter',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'Application/json',
                    },
                    body: JSON.stringify({ email: form.email }),
                },
            );
            const data = await res.json();
            if (data.error) {
                setResponse({ error: data.error });
                setForm({
                    ...form,
                    email: '',
                    error: data.error,
                    submitting: false,
                });
            } else {
                setResponse({ message: data.message });
            }
        } catch (err) {
            setResponse({ error: err.message });
            setForm({
                ...form,
                email: '',
                error: err.message,
                submitting: false,
            });
        }
    };
    return [response, send];
}
