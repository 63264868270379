import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Context } from '../context.js';
import useOnScreen from '../util/useOnScreen';
import { animate } from '../util/utils';
window.AudioContext = window.AudioContext || window.webkitAudioContext;

export default function Music() {
    const [ref, visible] = useOnScreen();

    return (
        <div ref={ref} className="music-section">
            <motion.div
                className="music-card"
                initial={animate.intersection.from}
                animate={
                    visible
                        ? animate.intersection.to
                        : animate.intersection.from
                }
            >
                <RdmWebAudioCard url="./resources/03 - Feinde.mp3" />
            </motion.div>
        </div>
    );
}

function RdmWebAudioCard({ url }) {
    const { value, setValue } = useContext(Context);
    const ac = React.useRef();
    const source = React.useRef();
    const analyser = React.useRef();
    const freqArray = React.useRef();
    const gain = React.useRef();

    const canvas = React.useRef();
    const cc = React.useRef();
    const raf = React.useRef();

    const [playState, setPlayState] = React.useState('stopped');

    useEffect(() => {
        cc.current = canvas.current.getContext('2d');
        ac.current = new window.AudioContext();
        if (ac.current) {
            createSource();
        }
        return () => {
            window.cancelAnimationFrame(raf.current);
            stopAudio();
        };
    }, []);

    useEffect(() => {
        if (value.activeMediaSrc !== 'rdmwa' && playState === 'playing') {
            stopAudio();
        }
    }, [value.activeMediaSrc]);

    async function fetchAudio() {
        setPlayState('loading');
        ac.current.resume();
        if (!source.current.buffer) {
            try {
                const response = await fetch(url);
                const data = await response.arrayBuffer();
                decodeAudio(data);
            } catch (e) {
                console.error(e);
            }
        } else {
            playAudio();
        }
    }

    function decodeAudio(buffer) {
        ac.current.decodeAudioData(buffer, decodedBuffer => {
            try {
                source.current.buffer = decodedBuffer;
                source.current.connect(analyser.current);
                analyser.current.connect(gain.current);
                gain.current.connect(ac.current.destination);
                source.current.addEventListener('onEnded', () => {
                    window.cancelAnimationFrame(raf.current);
                });
                playAudio();
            } catch (e) {
                console.error(e);
            }
        });
    }

    function playAudio() {
        setPlayState('playing');
        setValue({ ...value, activeMediaSrc: 'rdmwa' });
        source.current.start();
        drawVisuals();
    }

    function stopAudio() {
        setPlayState('stopped');
        gain.current.gain.linearRampToValueAtTime(
            0,
            ac.current.currentTime + 2,
        );
        source.current.stop(0);
        cc.current.width = cc.current.width;
        document.querySelector('#rdm-wa-play').remove();
        window.cancelAnimationFrame(raf.current);
    }
    function createSource() {
        source.current = ac.current.createBufferSource();
        analyser.current = ac.current.createAnalyser();
        analyser.current.fftSize = 1024;
        freqArray.current = new Uint8Array(512);
        gain.current = ac.current.createGain();
    }
    function drawVisuals() {
        const ccc = cc.current;
        const width = canvas.current.offsetWidth;
        const height = canvas.current.offsetHeight;

        const grd = ccc.createLinearGradient(0, 400, 0, 0);
        grd.addColorStop(0.5, '#1c6479');
        grd.addColorStop(1, '#933E3E');

        const freqData = new Uint8Array(analyser.current.frequencyBinCount);
        analyser.current.getByteFrequencyData(freqData);

        ccc.clearRect(0, 0, width, 900);
        const gap = 1;
        const step = 4;
        const barWidth = step - gap;
        let barHeight;

        freqData.forEach((i, x) => {
            if (x * step >= width) {
                return;
            }
            barHeight = (height / 256) * i * 0.8;
            ccc.fillStyle = grd;
            ccc.fillRect(x * step, height - barHeight, barWidth, height);
        });
        raf.current = window.requestAnimationFrame(drawVisuals);
    }

    return (
        <div className="rdm-wa-card ">
            <img src="resources/st_artwork.jpg" alt="artwork" />
            <button
                id="rdm-wa-play"
                className={`rdm-wa-card-btn ${playState}`}
                onClick={playState === 'playing' ? stopAudio : fetchAudio}
            ></button>
            <canvas
                ref={canvas}
                style={
                    playState !== 'playing'
                        ? { display: 'none' }
                        : { display: 'block' }
                }
            />
        </div>
    );
}
