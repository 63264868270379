import { useRef, useEffect, useState } from 'react';

export default function useOnScreen(options = { threshold: 0.2 }) {
    const ref = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // iOS 12 & < do not support IntersectionObeserver
        if (!window.IntersectionObserver) {
            setVisible(true);
            return;
        }
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);

    return [ref, visible];
}
