import { useEffect } from 'react'

export default function useLightSwitch(language) {
	useEffect(() => {
		if (language === "en") {
			document.body.classList.add('light-theme');
		} else {
			document.body.classList.remove('light-theme');
		}
	}, [language])
	return true;
}
