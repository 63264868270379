import React, {useContext} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {Context} from '../context.js';

export default function Greeting() {
    const {value} = useContext(Context);
    const anim = {
        initial: {
            y: -100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    };
    return value.lang === 'de' ? (
        <AnimatePresence transition={{delay: 1}}>
            <motion.div
                className="greeting"
                initial={anim.initial}
                animate={anim.animate}
                exit={anim.initial}
            >
                <h1>Hallo,</h1>
            </motion.div>
        </AnimatePresence>
    ) : (
        <motion.div
            className="greeting"
            initial={anim.initial}
            animate={anim.animate}
            exit={anim.initial}
        >
            <h1>Hello,</h1>
        </motion.div>
    );
}
