import React, { useEffect, useContext, useRef } from 'react';
import { Context } from '../context.js';
import useOnScreen from '../util/useOnScreen';
import { animate } from '../util/utils';
import { motion } from 'framer-motion';
import TextSelector from './TextSelector';

export default function BTBox() {
    const { value, setValue } = useContext(Context);
    const video = useRef(null);
    const [ref, visible] = useOnScreen();

    useEffect(() => {
        if (value.activeMediaSrc !== 'btbox') {
            video.current.pause();
        }
    }, [value.activeMediaSrc]);

    return (
        <div ref={ref}>
            <motion.video
                ref={video}
                className="shadow-box"
                src="./resources/btbox.mov#t=0"
                preload="metadata"
                controls
                initial={animate.intersection.from}
                animate={
                    visible
                        ? animate.intersection.to
                        : animate.intersection.from
                }
                playsInline
                poster="./resources/btbox-poster.png"
                onClick={() => {
                    setValue({ ...value, activeMediaSrc: 'btbox' });
                }}
            />
            <TextSelector>
                <i lang="de" className="caption">
                    Ziel war es Temperatur und Knebelposition via Bluetooth an
                    ein Endgerät zu senden und dort darzustellen.
                </i>
                <i lang="en" className="caption">
                    The goal was to display temperature and switch position on a
                    smartphone.
                </i>
            </TextSelector>
        </div>
    );
}
